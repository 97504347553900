import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class NewsletterPopup {
    constructor() {
        this.nl = document.querySelector('footer .mc4wp-form');
    }
    init() {
        const self = this;
        if (!sessionStorage.getItem('nl_closed')) {
            sessionStorage.setItem('nl_closed', 'false');
        }
        if (document.querySelectorAll('.nl-en').length > 0) {
            this.nl.querySelector('.nl-text-de').classList.add('hidden');
            this.nl.querySelector('.nl-mail').setAttribute('placeholder', 'Email');
            this.nl.querySelector('.nl-submit').setAttribute('value', 'Sign up');
        } else {
            this.nl.querySelector('.nl-text-en').classList.add('hidden');
        }
        Array.from(document.querySelectorAll('.nl-btn')).forEach(function (btn) {
            btn.addEventListener('click', self.openNewsletter.bind(self));
        });
        if (document.body.classList.contains('home')) {
            window.setTimeout(function () {
                if (sessionStorage.getItem('nl_closed') != 'true') {
                    self.openNewsletter(null);
                }
            }, 4000);
        }
        document.querySelector('footer .mc4wp-form .close-btn').addEventListener('click', function (evt) {
            self.nl.classList.remove('open-nl');
            sessionStorage.setItem('nl_closed', 'true');
            evt.preventDefault();
        });
        if (document.querySelectorAll('footer .mc4wp-form .mc4wp-response p').length > 0) {
            if (document.querySelector('footer .mc4wp-form .mc4wp-response p').innerText != "") {
                self.nl.classList.add('open-nl');
            }
        }
    }
    openNewsletter(evt) {
        const self = this;
        if (!self.nl.classList.contains('open-nl')) {
            self.nl.classList.add('open-nl');
        }
    }
}