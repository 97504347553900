import Swiper, { Navigation } from 'swiper';

export class ImgSlides {
    constructor(breakpointMedium) {
        this.speed = 700;
        this.breakpointMedium = breakpointMedium;
        this.swiperInstances = [];
    }

    init() {
        this.startSwiperSlides();
        window.addEventListener('resize', this.startSwiperSlides.bind(this));
    }
    startSwiperSlides(isenabled) {
        const self = this;
        if (document.querySelectorAll('.img-swiper-container').length > 0) {
            if (window.innerWidth > this.breakpointMedium) {
                Array.from(document.querySelectorAll('.img-swiper-container')).forEach(function (s) {
                    var slideshow = new Swiper(s, {
                        modules: [Navigation],
                        preloadImages: false,
                        lazy: true,
                        loop: false,
                        speed: 800,
                        grabCursor: true,
                        slidesPerView: 2,
                        spaceBetween: 12,
                        breakpoints: {
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 18,
                            },
                        },
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        on: {
                            init: function () {

                            },
                            slideChangeTransitionStart: function () {

                            }
                        },
                    });
                    self.swiperInstances.push(slideshow);
                });
            } else {
                var i = 0;
                for(i = 0; i < self.swiperInstances.length; i++) {
                    self.swiperInstances[i].destroy(true, true);
                }
            }
        }
    }
}