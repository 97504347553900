import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);

export class BaseFunctions {
    constructor(breakpointMedium) {
        this.anchorScroll = false;
        this.breakpointMedium = breakpointMedium;
    }
    init() {
        this.setArrowDown();
        this.setArrowUp();
        this.setParallax();
        window.addEventListener('resize', this.setParallax.bind(this));
        this.scrollToAnchorInit();
        this.headerMin();
        this.changeHeaderColor();
        this.setTextHeight();
        this.removeTextHeight();
        window.addEventListener('resize', this.removeTextHeight.bind(this));
        // this.setCoverHeight();
        if (!CSS.supports("aspect-ratio", "16/9")) {
            this.setImageRatio();
            window.addEventListener('resize', this.setImageRatio.bind(this));
        }
    }
    removeTextHeight() {
        if (window.innerWidth > this.breakpointMedium) {
            if (document.querySelectorAll('.text-content').length > 0) {
                Array.from(document.querySelectorAll('.text-content')).forEach(function (txt) {
                    txt.style.height = "";
                });
            }
        }
    }
    setTextHeight() {
        if (document.querySelectorAll('.text-content').length > 0) {
            Array.from(document.querySelectorAll('.text-content')).forEach(function (txt) {
                let h = 200;
                if (txt.querySelectorAll('p').length > 0) {
                    if (txt.querySelector('p').getBoundingClientRect().height > 195) {
                        h = 210;
                        txt.style.height = h + 'px';
                    }
                }
                txt.parentNode.querySelector('.read-more-btn').addEventListener('click', function () {
                    if (!txt.classList.contains('opened')) {
                        txt.classList.add('opened');
                        txt.style.height = txt.querySelector('.text-content-inner').getBoundingClientRect().height + 'px';
                        if (document.querySelector('#page').classList.contains('page-en')) {
                            txt.parentNode.querySelector('.read-more-btn').innerHTML = "read less";
                        } else {
                            txt.parentNode.querySelector('.read-more-btn').innerHTML = "weniger lesen";
                        }
                        txt.parentNode.querySelector('.read-more-btn').classList.add('space-b');
                    } else {
                        txt.classList.remove('opened');
                        txt.style.height = h + 'px';
                        if (document.querySelector('#page').classList.contains('page-en')) {
                            txt.parentNode.querySelector('.read-more-btn').innerHTML = "read more";
                        } else {
                            txt.parentNode.querySelector('.read-more-btn').innerHTML = "mehr lesen";
                        }
                        txt.parentNode.querySelector('.read-more-btn').classList.remove('space-b');
                    }
                });
            });
        }
    }
    setImageRatio() {
        if (document.querySelectorAll('.teaser-block').length > 0) {
            Array.from(document.querySelectorAll('.teaser-block')).forEach(function (img) {
                img.querySelector('.teaser-image img').style.height = img.querySelector('.teaser-image img').getBoundingClientRect().width / 16 * 9 + 'px';
            });
        }
        if (document.querySelectorAll('.teaserartist-block').length > 0) {
            Array.from(document.querySelectorAll('.teaserartist-block')).forEach(function (img) {
                img.querySelector('.teaser-image img').style.height = img.querySelector('.teaser-image img').getBoundingClientRect().width / 16 * 9 + 'px';
            });
        }

        if (document.querySelectorAll('.imgslider-section').length > 0) {
            Array.from(document.querySelectorAll('.imgslider-section .swiper-slide')).forEach(function (img) {
                img.querySelector('img').style.height = img.querySelector('img').getBoundingClientRect().width / 16 * 9 + 'px';
            });
        }
        if (document.querySelectorAll('.image-text-gallery').length > 0) {
            Array.from(document.querySelectorAll('.image-text-gallery figure')).forEach(function (img) {
                img.querySelector('img').style.height = img.querySelector('img').getBoundingClientRect().width / 16 * 9 + 'px';
            });
        }
    }
    setCoverHeight() {
        if (window.innerWidth <= this.breakpointMedium) {
            document.querySelector('.start-cover').style.height = window.innerHeight + 'px';
            document.querySelector('.cover-orange').style.height = window.innerHeight + 'px';
        }
    }
    changeHeaderColor() {
        if (document.body.classList.contains('home')) {
            window.addEventListener('scroll', this.detectBurgerCollision.bind(this), false);
            this.detectBurgerCollision();
        }
    }
    detectBurgerCollision() {
        let self = this;
        let arr = document.querySelectorAll('.cover-orange');
        Array.from(arr).forEach(function (el) {
            if (!self.isIntersecting(el)) {
                document.querySelector('.site-header svg').classList.add('orange-logo');
                document.querySelector('.site-header .header-inner').classList.add('has-white-background-color');
                if (window.innerWidth <= self.breakpointMedium) {
                    document.querySelector('.site-header .burger-icon-wrapper').classList.remove('white');
                }
            } else {
                document.querySelector('.site-header svg').classList.remove('orange-logo');
                document.querySelector('.site-header .header-inner').classList.remove('has-white-background-color');
                if (window.innerWidth <= self.breakpointMedium) {
                    document.querySelector('.site-header .burger-icon-wrapper').classList.add('white');
                }
            }
        });
    }

    isIntersecting(elem) {
        var bounding = elem.getBoundingClientRect();
        return (
            bounding.top <= 60 &&
            bounding.bottom >= window.innerHeight - 250
        );
    }
    headerMin() {
        const self = this;
        let scrollPos = window.scrollY;
        let lastScrollTop = 0;
        let scrollDirection = '';
        self.setHeaderPos(scrollDirection);
        window.addEventListener('scroll', function () {
            scrollPos = window.scrollY;
            if (scrollPos > lastScrollTop) {
                scrollDirection = 'down';
            } else {
                scrollDirection = 'up';
            }
            if (self.anchorScroll) {
                scrollDirection = 'down';
            }
            self.setHeaderPos(scrollDirection);
            lastScrollTop = scrollPos <= 0 ? 0 : scrollPos;
        });
    }
    setHeaderPos(scrollDirection) {
        const headerElem = document.querySelector('.site-header .header-inner');
        if (scrollDirection == 'down') {
            headerElem.classList.add('hide');
        } else {
            headerElem.classList.remove('hide');
        }
    }
    scrollToAnchorInit() {
        var self = this;
        //if (document.body.classList.contains('home')) {
        Array.from(document.querySelectorAll('a[href*="#"]')).forEach(function (anchor) {
            if (anchor.getAttribute('href') != '#newsletter') {
                anchor.addEventListener('click', function (e) {
                    self.anchorScroll = true;
                    self.scrollToAnchor(e, anchor.getAttribute('href'));
                    e.preventDefault();
                });
            } else {
                anchor.classList.add('nl-btn');
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();
                });
            }
        });
        //}
        if (window.location.hash) {
            //window.addEventListener('load', this.scrollToAnchor.bind(this));
        }
    }
    scrollToAnchor(evt, anchor) {
        if (anchor == undefined) {
            anchor = window.location.hash;
        }
        var self = this;
        let a = anchor.split('#')[1];
        if (a != null && a != undefined) {
            let p = document.getElementById(a).getBoundingClientRect().top + window.pageYOffset - 30;
            gsap.to(window, { duration: 1, scrollTo: p, ease: "power3.out", onComplete: self.resetAnchorSCroll, onCompleteParams: [self] });
        }
    }
    resetAnchorSCroll(self) {
        self.anchorScroll = false;
    }
    setArrowDown() {
        if (document.querySelectorAll('.arrow-down').length > 0) {
            document.querySelector('.arrow-down').addEventListener('click', function () {
                let p = document.querySelector('.cover-orange').getBoundingClientRect().height;
                gsap.to(window, { duration: 1.0, scrollTo: p, ease: "power3.out" });
            });
        }
    }
    setArrowUp() {
        if (document.querySelectorAll('.arrow-up').length > 0) {
            document.querySelector('.arrow-up').addEventListener('click', function () {
                let p = window.innerHeight;
                gsap.to(window, { duration: 1.0, scrollTo: 0, ease: "power3.out" });
            });
        }
    }
    setParallax() {
        if (window.innerWidth >= this.breakpointMedium) {
            if (document.querySelectorAll('.start-cover').length > 0) {
                window.addEventListener('scroll', this.setParallaxPositions.bind(this));
                this.setParallaxPositions();
            }
        }
    }
    setParallaxPositions() {
        const img = document.querySelector('.cover-img');
        const arr = document.querySelector('.arrow-down');
        const main = document.querySelector('.site-main');
        let scrollPos = window.scrollY;
        let dist = window.innerHeight - document.querySelector('.cover-orange').getBoundingClientRect().height;
        let yPos = window.innerHeight / (dist + window.innerHeight / 3);
        if (scrollPos / yPos < dist) {
            gsap.set(img, { y: -scrollPos / yPos });
            gsap.set(main, { y: -scrollPos / yPos });
        } else {
            gsap.set(img, { y: -dist });
            gsap.set(main, { y: -dist });
        }
        if (scrollPos > 30) {
            gsap.to(arr, { opacity: 0, duration: 0.2 });
        } else {
            gsap.to(arr, { opacity: 1, duration: 0.4 });
        }
    }
}