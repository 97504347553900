export class Slideshow {
    constructor(breakpointMedium) {
        this.slideshow = document.querySelector('.image-layer');
        this.slider = document.querySelector('.img-swiper-container');
        this.currentIndex = 0;
        this.mailText = this.slideshow.querySelector('.large-img .mail').getAttribute('href');
        this.breakpointMedium = breakpointMedium;
    }

    init() {
        const self = this;
        this.slideshow.style.height = window.innerHeight + 'px';
        window.addEventListener('resize', function () {
            self.slideshow.style.height = window.innerHeight + 'px';
        });
        Array.from(document.querySelectorAll('.img-swiper-container img')).forEach(function (img, index) {
            img.addEventListener('click', function (evt) {
                if (window.innerWidth > self.breakpointMedium ) {
                    self.currentIndex = index;
                    self.openSLideshow(img);
                }
            });
        });
        this.slideshow.querySelector('.back-btn').addEventListener('click', function () {
            self.closeSLideshow();
        });
        this.slideshow.querySelector('.next-btn').addEventListener('click', function () {
            self.getNext();
        });
        this.slideshow.querySelector('.prev-btn').addEventListener('click', function () {
            self.getPrev();
        });
    }
    openSLideshow(img) {
        this.slideshow.classList.add('show');
        document.body.classList.add('no-scroll');
        this.slideshow.querySelector('.large-img img').src = img.getAttribute('data-largeimg');
        if (img.getAttribute('data-text') != "") {
            this.slideshow.querySelector('.large-img .text').innerText = img.getAttribute('data-text');
            this.slideshow.querySelector('.large-img .mail').setAttribute('href', this.mailText + img.getAttribute('data-mail'));
            this.slideshow.querySelector('.large-img .text-wrapper').classList.remove('hidden');
        } else {
            this.slideshow.querySelector('.large-img .text').innerText = "";
            this.slideshow.querySelector('.large-img .text-wrapper').classList.add('hidden');
        }
        this.setCount();
        this.checkDisabled();
    }
    closeSLideshow() {
        this.slideshow.classList.remove('show');
        document.body.classList.remove('no-scroll');
    }
    setCount() {
        this.slideshow.querySelector('.count-btn').innerText = (this.currentIndex + 1) + " / " + this.slider.querySelectorAll('.swiper-slide').length;
    }
    getNext(btn) {
        var total = this.slider.querySelectorAll('.swiper-slide').length;
        if (this.currentIndex < total - 1) {
            this.currentIndex++;
            this.slideshow.querySelector('.large-img img').src = this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-largeimg');
            if (this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-text') != "") {
                this.slideshow.querySelector('.large-img .text').innerText = this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-text');
                this.slideshow.querySelector('.large-img .mail').setAttribute('href', this.mailText + this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-mail'));
                this.slideshow.querySelector('.large-img .text-wrapper').classList.remove('hidden');
            } else {
                this.slideshow.querySelector('.large-img .text').innerText = "";
                this.slideshow.querySelector('.large-img .text-wrapper').classList.add('hidden');
            }
        } else {
            //this.slideshow.querySelector('.next-btn').classList.add('disabled');
        }
        this.checkDisabled();
        this.setCount();
    }
    getPrev(btn) {
        var total = this.slider.querySelectorAll('.swiper-slide').length;
        if (this.currentIndex > 0) {
            this.currentIndex--;
            this.slideshow.querySelector('.large-img img').src = this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-largeimg');
            if (this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-text') != "") {
                this.slideshow.querySelector('.large-img .text').innerText = this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-text');
                this.slideshow.querySelector('.large-img .mail').setAttribute('href', this.mailText + this.slider.querySelectorAll('.swiper-slide')[this.currentIndex].querySelector('img').getAttribute('data-mail'));
                this.slideshow.querySelector('.large-img .text-wrapper').classList.remove('hidden');
            } else {
                this.slideshow.querySelector('.large-img .text').innerText = "";
                this.slideshow.querySelector('.large-img .text-wrapper').classList.add('hidden');
            }
        } else {
            //this.slideshow.querySelector('.prev-btn').classList.add('disabled');
        }
        this.checkDisabled();
        this.setCount();
    }
    checkDisabled() {
        if (this.currentIndex == 0) {
            this.slideshow.querySelector('.prev-btn').classList.add('disabled');
        } else {
            this.slideshow.querySelector('.prev-btn').classList.remove('disabled');
        }
        if (this.currentIndex == this.slider.querySelectorAll('.swiper-slide').length - 1) {
            this.slideshow.querySelector('.next-btn').classList.add('disabled');
        } else {
            this.slideshow.querySelector('.next-btn').classList.remove('disabled');
        }
    }
}