export class BurgerNavi {
    constructor(base) {
        this.base = base;
        this.menuButton = document.getElementById('menuButton');
        this.mainNavWrapper = document.getElementById('mainNavWrapper');
    }

    init() {
        const self = this;
        this.menuButton.addEventListener('click', this.openNav.bind(this));
        Array.from(this.mainNavWrapper.querySelectorAll('li')).forEach(function (ne) {
            ne.addEventListener('click', function (e) {
                self.menuButton.classList.remove('is-active');
                self.mainNavWrapper.classList.add('closed');
            });
        });
        if (document.querySelectorAll('.single-landingpages').length == 0) {
            window.addEventListener('scroll', this.closeNav.bind(this));
            console.log('HEADER MIN');
        }
    }

    openNav(evt) {
        if (this.mainNavWrapper.classList.contains('hide-nav')) {
            this.mainNavWrapper.classList.remove('hide-nav');
        }
        if (!this.menuButton.classList.contains('is-active')) {
            this.menuButton.classList.add('is-active');
            this.mainNavWrapper.classList.remove('closed');
            this.hideOnClickOutside('header#masthead');
        } else {
            this.menuButton.classList.remove('is-active');
            this.mainNavWrapper.classList.add('closed');
        }
        if (evt != null) {
            evt.preventDefault();
        }
    }
    closeNav() {
        if (this.mainNavWrapper.classList.contains('hide-nav')) {
            this.mainNavWrapper.classList.remove('hide-nav');
        }
        this.menuButton.classList.remove('is-active');
        this.mainNavWrapper.classList.add('closed');
    }
    hideOnClickOutside(element) {
        let self = this;

        const outsideClickListener = event => {
            if (event.target.closest(element) === null) {
                if (!self.mainNavWrapper.classList.contains('closed')) {
                    self.openNav(null);
                    removeClickListener();
                }
            }
        };

        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        };

        document.addEventListener('click', outsideClickListener);
    }
}

