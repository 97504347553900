import { BaseFunctions } from "./js/basefunctions.js";
import { BurgerNavi } from "./js/burgernavi.js";
import { ImgSlides } from "./js/imgslides.js";
import { Slideshow } from "./js/slideshow.js";
import { ArtistSidebar } from "./js/artistsidebar.js";
import { NewsletterPopup } from "./js/newsletterpopup.js";

const breakpointMedium = 768;
const breakpointLarge = 1024;

document.addEventListener("DOMContentLoaded", function (event) {

    const basefunctions = new BaseFunctions(breakpointMedium);
    basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init();

    if (document.querySelectorAll('.img-swiper-container').length > 0) {
        const imgslides = new ImgSlides(breakpointMedium);
        imgslides.init();
        const slideshow = new Slideshow(breakpointMedium);
        slideshow.init();
        if (!CSS.supports("aspect-ratio", "16/9")) {
            basefunctions.setImageRatio();
        }
    }
    if (document.querySelectorAll('.page-template-page-artists').length > 0) {
        const artistsidebar = new ArtistSidebar();
        artistsidebar.init();
    }
    if (!document.querySelectorAll('.newsletter-page').length > 0) {
        const newsletterpopup = new NewsletterPopup();
        newsletterpopup.init();
    }
});