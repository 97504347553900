import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class ArtistSidebar {
    constructor(breakpointMedium) {
        this.breakpointMedium = breakpointMedium;
        this.dist = 0;
        this.topPos = -1;
    }
    init() {
        this.setArtistsSidebar();
        window.addEventListener('resize', this.setArtistsSidebar.bind(this));
    }

    setArtistsSidebar() {
        this.dist = document.querySelectorAll('.teaserartist-block')[0].parentNode.parentNode.getBoundingClientRect().top - document.querySelector('.site-header').getBoundingClientRect().height;
        document.querySelector('.page-template-page-artists .sidebar-inner').style.marginTop = this.dist + 'px';
        window.addEventListener('scroll', this.moveSidebar.bind(this));
        this.moveSidebar();
    }
    moveSidebar() {
        const sb = document.querySelector('.page-template-page-artists .scroll-elem');
        var endPos = sb.parentNode.parentNode.getBoundingClientRect().height - this.dist;
        var p = document.querySelectorAll('.teaserartist-block')[0].parentNode.parentNode.getBoundingClientRect().top; 
        var contentHeight = document.querySelector('.site-main .container').getBoundingClientRect().height;
        gsap.set(sb, { y: -window.scrollY / 3 });

    }
}